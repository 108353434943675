import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Img from "gatsby-image";
import SEO from "../components/seo";
import styled from 'styled-components';

const Portfolio = ({ data }) => {
  return (
    <Layout>
      <SEO title="Works" />
      <MainContent>
        <Headline>Works</Headline>
        {data.allContentfulBlogPost.edges.map(({ node }) => (
        <div className="pl-Content__Wrap">
            <Link to={`/portfolio/${node.slug}`}>
                <MainContentImage>
                    <Img fluid={node.thumbnail.fluid} />
                </MainContentImage>
                <SubHeadline>
                    {node.title}
                </SubHeadline>
            </Link>
            <TagList>
                {node.tags.map(tag => (
                  <TagListItem>
                    {tag}
                  </TagListItem>
                ))}
            </TagList>
        </div>
        ))}
        </MainContent>
    </Layout>
  )
};

const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 980px;
    width: 100%;
    margin: 0 auto;

    & a {
        text-decoration: none;
        color: #000;
    }

    @media (max-width: 768px) {
      
    }
`

const MainContentImage = styled.div`
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
`

const Headline = styled.h1`
    font-size: 3rem;
    font-weight: 300;
    letter-spacing: 3px;
`

const SubHeadline = styled.p`
    font-size: 2rem;
    font-weight: 100;
    text-align: center;
`

const TagList = styled.ul`
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 1.4em 0 0.5em;
`

const TagListItem = styled.li`
    width: calc(50% - 20px);
    text-align: center;
    background: #a3c7fc;
    padding: 0.3em;
    margin-right: 0.7em;
    border-radius: 7px;
    color: #000;
`

export default Portfolio;

export const query = graphql`
  {
    allContentfulBlogPost {
      edges {
        node {
          title
          slug
          tags
          thumbnail {
            fluid(maxWidth: 1000, quality: 90) {
                ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    },
    contentfulTopIntroduction {
      introduction {
        introduction
      }
    }
  }
`
